export default function(value) {
	const errors = {};

	errors.minLength = value.length < 8;

	errors.lowerCaseLetter = !value.match(/[a-z]/);

	errors.upperCaseLetter = !value.match(/[A-Z]/);

	errors.number = !value.match(/[0-9]/);

	errors.specialCharacter = !value.match(new RegExp('(?=.[@#$%^&+=])'));

	const isPasswordValid = Object.values(errors).every(error => error === false);

	return { errors, isPasswordValid };
}
