/* eslint-disable no-unused-expressions */
import $ from 'jquery';

export default class InfiniteScroll {
	constructor(rowsInTable = 0, numberOfRowsFactor = 3, pxOffsetScrollEvent = 150) {
		this.rowsInTable = rowsInTable;
		this.numberOfRowsFactor = numberOfRowsFactor;
		this.pxOffsetScrollEvent = pxOffsetScrollEvent;
	}

	setRows(rows) {
		// Outside rows setter, maybe this should be only reset?
		this.rowsInTable = rows;
	}

	static controlNumberOfItemsText(numberOfItems, selector) {
		//TODO: So far only one usage - for smart tables, could/should be build more complex in future
		const split = selector.text().split(' ');

		split[5] = numberOfItems;
		split[9] ? (split[9] = numberOfItems) : '';
		selector.text(split.join(' '));
	}

	loadMoreDataIntoInfiniteScroll(container, table, callback) {
		const that = this;
		container.on('scroll', () => {
			const scrollTop = $(this).scrollTop();
			const maxScroll = table.height() - $(this).height();

			if (maxScroll > 1 && scrollTop > maxScroll - that.pxOffsetScrollEvent) {
				callback();
			}
		});
	}

	static numberOfRowsThatCanFitIntoTable(tableHeight, rowHeight) {
		return Math.floor(tableHeight / rowHeight) + 5;
	}

	controlScrollItems(data, tableHeight, rowHeight) {
		const numberOfRows =
			InfiniteScroll.numberOfRowsThatCanFitIntoTable(tableHeight, rowHeight) * this.numberOfRowsFactor;

		const limit = numberOfRows + this.rowsInTable;

		const newData = data.data.filter((item, i) => i >= this.rowsInTable && i < limit);

		this.rowsInTable = limit;

		return newData;
	}
}
