// unfortunately, we still user this thing
export default function determineFileType(mimeType) {
	switch (mimeType) {
		case 'application/vnd.gogo.fs.folder':
			return 'FolderOpen';

		case 'image/svg+xml':
		case 'image/png':
		case 'image/png;charset=UTF-8':
			return 'FileImage';

		case 'application/pdf':
			return 'FilePdf';

		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		case 'application/vnd.ms-excel':
			return 'FileExcel';

		case 'text/plain':
		case 'text/rtf':
			return 'FileTextO';

		default:
			return 'FileO';
	}
}
