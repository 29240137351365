export function toBinaryString(data) {
	const ret = [];
	const len = data.length;
	let byte;
	for (let i = 0; i < len; i++) {
		//eslint-disable-next-line
		byte = (data.charCodeAt(i) & 0xff) >>> 0;
		ret.push(String.fromCharCode(byte));
	}

	return ret.join('');
}

export default function downloadFile(url, token) {
	const xhr = new XMLHttpRequest();
	xhr.open('GET', url);
	xhr.addEventListener(
		'load',
		() => {
			let data = toBinaryString(xhr.responseText);
			data = `data:application/zip;base64,${btoa(data)}`;
			document.location = data;
		},
		false
	);

	xhr.setRequestHeader('X-Auth-Token', token);
	xhr.overrideMimeType('application/octet-stream; charset=x-user-defined;');
	xhr.send(null);
}
