import '@babel/runtime-corejs2/core-js/symbol';

export default class OrderedObject {
	constructor(initial, arg2 = null) {
		this.order = [];

		if (typeof initial === 'object') {
			if (initial.order) {
				throw new Error('order is a reserved word.');
			}
			if (initial instanceof Array && typeof arg2 === 'string') {
				for (let i = 0, len = initial.length; i < len; i++) {
					const id = initial[i][arg2];
					this.order.push(id);
					this[id] = initial[i];
				}
			} else if (arg2 instanceof Array) {
				this.order = [...arg2];
				for (const key in initial) {
					if (Object.prototype.hasOwnProperty.call(initial, key)) this[key] = initial[key];
				}
			} else {
				for (const key in initial) {
					if (Object.prototype.hasOwnProperty.call(initial, key)) {
						this.order.push(key);
						this[key] = initial[key];
					}
				}
			}
		} else if (initial) {
			this.order = ['1'];
			this['1'] = initial;
		}
	}

	*[Symbol.iterator]() {
		let i = 0;
		const len = this.order.length;

		while (i < len) {
			yield this[this.order[i++]];
		}
	}

	forEach(callback, thisArg) {
		this.order.forEach(x => {
			callback.call(thisArg, this[x], x, this);
		});
	}

	map(callback, thisArg) {
		return this.order.map(x => callback.call(thisArg, this[x], x, this));
	}

	reduce(callback, initialValue) {
		return this.order.reduce(
			(previousValue, currentValue, currentIndex) =>
				callback(previousValue, this[currentValue], this.order[currentIndex], this),
			initialValue
		);
	}

	size() {
		return this.order.length;
	}

	toArray() {
		return this.map(x => x);
	}
}
