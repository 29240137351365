export default function hasPermission(userPermissions, permission) {
	if (
		!userPermissions ||
		typeof userPermissions != 'object' ||
		typeof permission != 'object' ||
		!userPermissions.length ||
		!permission ||
		!permission.domain
	) {
		return false;
	}

	return userPermissions.some(userPermission => {
		if (userPermission.domain !== permission.domain) {
			return false;
		}

		if (permission.target && userPermission.target !== permission.target) {
			return false;
		}

		if (permission.action) {
			if ('actions' in userPermission) {
				if (userPermission.actions === null) {
					return true;
				}

				if (userPermission.actions instanceof Array) {
					if (userPermission.actions.includes('*')) {
						return true;
					}

					if (userPermission.actions.includes(permission.action)) {
						return true;
					}
				}
			} else if ('action' in userPermission) {
				if (userPermission.action === null) {
					return true;
				}

				if (userPermission.action == '*') {
					return true;
				}

				if (userPermission.action == permission.action) {
					return true;
				}
			}

			return false;
		}

		return true;
	});
}
