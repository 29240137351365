/* eslint-disable no-param-reassign */
export default function(roles, permissions, page) {
	const permissionsFlat = !permissions
		? []
		: permissions.map(
				permission => (permission.target && permission.target != '*' ? permission.target : permission.domain)
		  );

	if (!(roles instanceof Array)) {
		roles = [...roles];
	}

	const multiPage = page instanceof Array;

	const hasAccessBasedOnPermissions = multiPage
		? page.some(singlePage => permissionsFlat.indexOf(singlePage.extraPermission))
		: permissionsFlat.indexOf(page.extraPermission) >= 0;

	const hasAccessBasedOnRole = multiPage
		? page.some(singlePage => singlePage.roles.some(role => roles.indexOf(role) >= 0))
		: page.roles.some(role => roles.indexOf(role) >= 0);

	return hasAccessBasedOnRole || hasAccessBasedOnPermissions;
}
