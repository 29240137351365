import hasPermission from './hasPermission';

export default function(roles, permissions, page) {
	let paramPage = page;

	if (paramPage instanceof Array) {
		[paramPage] = paramPage;
	}

	return typeof paramPage.permission === 'function'
		? paramPage.permission(permissions)
		: hasPermission(permissions, paramPage.permission);
}
