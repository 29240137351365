/* eslint-disable no-param-reassign */

const EMPTY_VALUES = ['', null, undefined];

export const removeEmpty = obj => {
	// Returns object whose values are non empty
	const newObj = {};
	Object.keys(obj).forEach(key => {
		if (Object.prototype.hasOwnProperty.call(obj, key) && EMPTY_VALUES.indexOf(obj[key]) < 0) {
			newObj[key] = obj[key];
		}
	});

	return newObj;
};

export const toCommaSeparated = obj => {
	if (!obj) return '';

	return Object.keys(obj)
		.reduce((keyValueStrings, key) => {
			const value = obj[key];
			if (value !== null) keyValueStrings.push(`${key}: ${value}`);
			return keyValueStrings;
		}, [])
		.join(', ');
};

export const hasTruthyField = (obj, truthyValidator) =>
	Object.keys(obj).findIndex(key => {
		if (truthyValidator) return truthyValidator(obj[key]);
		return !!obj[key];
	}) >= 0;

// objectsAreEquivalent implements the logic historically present in BIA when filters sets are
// compared. The function is not for general use and should be removed once the logic in BIA is
// revised.
export const objectsAreEquivalent = (origObjects, targObjects, hashFunc) => {
	const hasEquivalentObjectUnderName = (needle, name, haystack, hashFnc) => {
		const needleHash = hashFnc(needle);
		const needleIsValid = !!needleHash;

		const haystackIsValid = !!haystack;
		const strawHash = haystackIsValid ? hashFnc(haystack[name]) : '';
		const strawIsValid = !!strawHash;

		const bothAreInvalid = !needleIsValid && !strawIsValid;

		return bothAreInvalid || needleHash === strawHash;
	};

	const findObjectWithoutEquivalent = (origObj, targObj, hashFn) =>
		Object.keys(origObjects).find(name => !hasEquivalentObjectUnderName(origObj[name], name, targObj, hashFn));

	const objectWithoutEquivalent = findObjectWithoutEquivalent(origObjects, targObjects, hashFunc);
	return objectWithoutEquivalent === undefined;
};

// Not used anywhere :/
export const objectValuesToArray = object => Object.keys(object).map(key => object[key]);

const isShallowSubset = (subset, superset, eqOperator) => {
	if (!eqOperator) {
		eqOperator = (param1, param2) => param1 === param2;
	}

	const indexOfMissingElement = Object.keys(superset).findIndex(key => {
		const FOUND_MISSING = true;
		const ELEMENT_HAS_MATCH = false;

		const subsetHasKey = Object.prototype.hasOwnProperty.call(subset, key);
		if (!subsetHasKey) {
			return FOUND_MISSING;
		}
		const subsetHasSameValueForKey = eqOperator(superset[key], subset[key]);
		if (!subsetHasSameValueForKey) {
			return FOUND_MISSING;
		}
		return ELEMENT_HAS_MATCH;
	});

	return indexOfMissingElement < 0;
};

export const isShallowEqual = (obj1, obj2, eqOperator) => {
	const obj1IsValidObject = obj1 && typeof obj1 === 'object';
	const obj2IsValidObject = obj2 && typeof obj2 === 'object';
	if (!obj1IsValidObject || !obj2IsValidObject) {
		return obj1 === obj2;
	}

	return isShallowSubset(obj1, obj2, eqOperator) && isShallowSubset(obj2, obj1, eqOperator);
};
