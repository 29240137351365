export default function(obj) {
	const ret = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			ret[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
		}
	}

	return ret;
}
