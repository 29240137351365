import bugsnag from './bugsnagConfig';

export default function(fileName, date, metaData = {}) {
	bugsnag.notify(`Undead code in ${fileName}`, {
		metaData: {
			tombstone: {
				file: fileName,
				date,
				...metaData,
			},
		},
		severity: 'warning',
		context: 'Tombstone',
	});
}
