const transformData = (data = [], mapNames) => {
	const groupData = {};
	const transformedData = [];
	if (Array.isArray(data)) {
		data.forEach(partner => {
			if (!groupData[partner.type]) {
				groupData[partner.type] = {
					partners: [
						{
							text: mapNames
								? partner.type === 'Airline'
									? partner.partner_code
									: partner.partner_name
								: partner.partner_name,
							value: partner.partner_code,
						},
					],
				};
			} else {
				groupData[partner.type].partners.push({
					text: mapNames
						? partner.type === 'Airline'
							? partner.partner_code
							: partner.partner_name
						: partner.partner_name,
					value: partner.partner_code,
				});
			}
		});

		for (const partner in groupData) {
			if (Object.prototype.hasOwnProperty.call(groupData, partner)) {
				transformedData.push({ groupName: partner.replace(/_/g, ' '), options: groupData[partner].partners });
			}
		}

		return transformedData.sort((a, b) => {
			if (a.firstname < b.firstname) return -1;
			if (a.firstname > b.firstname) return 1;
			return 0;
		});
	}

	return data;
};

export default transformData;
