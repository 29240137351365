/* eslint-disable no-param-reassign */
import validationRules from 'formsy-react/lib/validationRules';
import { splitToStrings } from './general';

export default {
	isNotWhitespaceOnly: value => !!(value && `${value}`.trim()), // Returns false if value contains only whitespaces
	atLeastOneSelected: value => typeof value === 'object' && value.length,
	productCodeRegExp: /^[a-zA-Z0-9]{6}[0-9]{4}$/,
	isInBetween: (value, array) => {
		if (value === undefined) {
			return false;
		}
		// Whenever we pass mixed type arguments, like [1,2, 'integer'], instead of an array,
		// the string "[1,2,'integer']" gets passed so we need to convert it to array
		if (typeof array === 'string') {
			array = JSON.parse(array.replace(/'/g, '"'));
		}

		if (typeof parseInt(value) !== 'number') {
			return false;
		}

		// If the latest parameter is type and value does not conform to the type, return false;
		if (array[array.length - 1] === 'integer' && value % 1 !== 0) {
			return false;
		} // Not integer

		if (array.length >= 2) {
			return array[0] <= value && value <= array[1];
		}

		return array.length[0] <= value;
	},
	isValidCMPCardExpirationMonth: (values, value) => {
		if (!value) {
			return true;
		}

		const matches = value.match(/^([0-1]\d)-(\d{4})$/);

		if (!matches) {
			return false;
		}

		const [month, year] = matches;

		if (!month || !year) {
			return false;
		}

		return !(month > 12 || month < 1);
	},
	isPositive: value => value > 0,
	areValidEmails: (text, minEmailCount = 1) => {
		const emails = splitToStrings({
			text,
			regexpSplitter: /\s+/, // splitting text on space(s), tab(s) or new line(s) into array of strings
			removeWhitespaceResults: true,
		});
		return emails.length >= minEmailCount && emails.every(email => validationRules.isEmail('', email));
	},
};
