export const grab = (object, path, i = 0) => {
	if (!path) {
		return undefined;
	}

	const split = path.split('.');
	let arraySplit;
	let arraySplitIndex;

	if (split[i].indexOf('[') !== -1) {
		[arraySplit] = split[i].split('[');
		arraySplitIndex = split[i].split('[')[1].replace(/[^/\d]/g, '');
	}

	const value =
		arraySplit && object[arraySplit]
			? object[arraySplit][arraySplitIndex]
			: object[split[i]]
				? object[split[i]]
				: undefined;

	if (!value) {
		return undefined;
	}

	if (split.length > i + 1) {
		return grab(value, path, i + 1);
	}
	return value;
};

export const debounce = (func, wait, immediate) => {
	let timeout;
	return () => {
		const args = arguments;
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(this, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(this, args);
	};
};

export const getParameterByName = (name, url) => {
	//eslint-disable-next-line
	if (!url) url = window.location.href;
	//eslint-disable-next-line
	name = name.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const splitToStrings = ({
	text,
	regexpSplitter = /\s+/, // by default splitting text on space(s), tab(s) or new line(s) into array of strings
	removeWhitespaceResults = true,
}) => {
	if (text === undefined || text === null) {
		return [];
	}
	if (typeof text !== 'string') {
		throw new Error(
			`splitToStrings requires text argument to be string. Instead received ${typeof text} = ${text}`
		);
	}
	const strings = text.split(regexpSplitter);
	if (!removeWhitespaceResults) {
		return strings;
	}
	// removing whitespace strings and empty strings from result array
	return strings.filter(s => s.trim() !== '');
};

export const objectToFormData = obj => {
	const formData = new FormData();
	Object.entries(obj).forEach(([key, value]) => formData.append(key, value));
	return formData;
};
