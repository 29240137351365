// From http://momentjs.com/docs/#/parsing/string-format/
export default {
	DAY: 'day',
	DAYS: 'days',
	MONTHS: 'months',
	WEEKS: 'weeks',
	YEAR: 'year',
	YEARS: 'years',
	UNIX_TIME_FORMAT: 'x',
};
