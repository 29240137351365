import bugsnag from 'bugsnag-js';

//eslint-disable-next-line
const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');
const currentUrl = window.location.href;

let env = 'local';

if (currentUrl.includes('partner-dev.')) {
	env = 'development';
} else if (currentUrl.includes('partner-stage.')) {
	env = 'staging';
} else if (currentUrl.includes('partner.')) {
	env = 'production';
}

const bugsnagClient = isBrowser()
	? bugsnag({
			apiKey: '3ef1d715cf5da378845c25376e7a237a',
			notifyReleaseStages: ['development', 'staging', 'production'],
			consoleBreadcrumbsEnabled: false,
			releaseStage: env,
			autoCaptureSessions: true,
			//eslint-disable-next-line
			collectUserIp: false,
	  })
	: {
			notify() {},
			leaveBreadcrumb() {},
			config: {
				beforeSend: [],
			},
	  };

export default bugsnagClient;
