// Curry function that allows different functions to be performed on datatables data as a render callback

export function changeDataType(fn, handledTypes) {
	if (typeof handledTypes == 'string') {
		//eslint-disable-next-line
		handledTypes = [...handledTypes];
	}

	return (data, type) => {
		if (!handledTypes || handledTypes.includes(type)) {
			return fn.call(null, data, type);
		}

		return data;
	};
}

export function stringifyDisplayType(data) {
	return data ? `${data}` : '';
}
