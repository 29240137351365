export default function sortNumbers(a, b) {
	let paramA = a;
	let paramB = b;

	if (paramA === null || paramA === undefined || paramA === '') {
		paramA = -Infinity;
	} else {
		paramA = Number(a);
	}

	if (paramB === null || paramB === undefined || paramB === '') {
		paramB = -Infinity;
	} else {
		paramB = Number(b);
	}

	return paramA - paramB;
}
