// JSType from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
export const JSType = {
    OBJECT: 'object',
    STRING: 'string',
    NUMBER: 'number',
    UNDEFINED: 'undefined',
    BOOLEAN: 'boolean',
    SYMBOL: 'symbol',
    FUNCTION: 'function',
};

// JSMouseButton from https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
export const JSMouseButton = {
    LEFT: 0,
    MIDDLE: 1,
    RIGHT: 2,
    BACK: 3,
    FORWARD: 4
};