class Converter {
	toK = b => b / 1e3;
	toM = b => b / 1e6;
	toG = b => b / 1e9;
	toT = b => b / 1e12;
	toKi = b => b / 1024;
	toMi = b => b / 1048576;
	toGi = b => b / 1073741824;
	toTi = b => b / 1.0995116e12;
}
const unitConverter = new Converter();

export default unitConverter;
