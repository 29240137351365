import omit from 'lodash.omit';

export const getColumns = columns => {
	const exportColumns = {};

	columns.filter(column => !column.excludeFromExport && !column.expander).forEach((column, i) => {
		if (column.Header && typeof column.Header === 'string') {
			exportColumns[i] = column.Header;
		} else {
			exportColumns[i] = column.exportHeader;
		}
	});

	return exportColumns;
};

const deleteInternalProps = obj => {
	const keysToDelete = Object.keys(obj).filter(key => key.startsWith('_'));

	return omit(obj, keysToDelete);
};

export const getData = (data, excludedIndexes = []) => {
	const pureData = data.map(item => {
		const newObj = { ...item };

		return deleteInternalProps(newObj);
	});

	return pureData.map(row => {
		const returnData = {};
		const rowData = Object.values(row).filter((rowDataItem, index) => !excludedIndexes.includes(index));

		if (row) {
			rowData.forEach((item, i) => {
				returnData[i] = typeof item === 'string' || typeof item === 'number' ? item : null;
			});
		}

		return row ? returnData : {};
	});
};

export const getExportData = (data, columns) => {
	const excludedIndexes = columns
		.map((column, index) => (column.excludeFromExport ? index : null))
		.filter(val => val !== null);

	return {
		columns: getColumns(columns),
		data: getData(data, excludedIndexes),
	};
};
