// this is not used, but seems it can be useful sometimes
export function areArraysEqualIgnoreOrder(arr1, arr2) {
	const { length } = arr1;
	if (length !== arr2.length) {
		return false;
	}
	// Make a copy of array, so we preserve orders in the original arrays
	const a1 = [...arr1].sort();
	const a2 = [...arr2].sort();

	let i = 0;
	while (i < length && a1[i] === a2[i]) {
		i++;
	}
	return i === a1.length;
}

export function splitArrayIntoChunks(arr, numberOfChunks) {
	const len = arr.length;
	const out = [];
	let i = 0;

	while (i < len) {
		//eslint-disable-next-line
		const size = Math.ceil((len - i) / numberOfChunks--);
		out.push(arr.slice(i, (i += size)));
	}

	return out;
}

// Returns array with unique values
export const arrUnique = a => {
	const t = [];
	for (let x = 0; x < a.length; x++) {
		if (t.indexOf(a[x]) == -1) t.push(a[x]);
	}
	return t;
};
