import bugsnag from './bugsnagConfig';

export default function selectText(node) {
	if (document.body.createTextRange) {
		const range = document.body.createTextRange();
		range.moveToElementText(node);
		range.select();
	} else if (window.getSelection) {
		const selection = window.getSelection();
		const range = document.createRange();
		range.selectNodeContents(node);
		selection.removeAllRanges();
		selection.addRange(range);
	} else {
		bugsnag.notify('Browser has neither document.body.createTextRange nor window.getSelection', {
			severity: 'warning',
			context: 'Failed to select text',
		});
	}
}
