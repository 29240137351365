// purpose of this util is to take array of objects and search text
// and to return array with objects that have at least one prop that contains search text
import pick from 'lodash.pick';

export default function(data, searchText, keys, deepSearch = false) {
	const criteria = typeof searchText === 'string' ? searchText.trim() : searchText;

	return data.filter(item => {
		const searchableData = keys ? pick(item, keys) : item;
		let values;
		if (deepSearch) {
			values = Object.values(searchableData).map(
				value => (typeof value === 'string' || typeof value === 'number' ? value : JSON.stringify(value))
			);
		} else {
			values = Object.values(searchableData).filter(
				value => typeof value === 'string' || typeof value === 'number'
			);
		}
		return values
			.join(',')
			.toLowerCase()
			.includes(criteria);
	});
}
