import moment from 'moment';

const sortDates = (a, b) => {
	let momentA = moment(a);
	let momentB = moment(b);

	// force null and undefined to the bottom
	if (momentA === null || momentA === undefined) {
		momentA = -Infinity;
	}

	if (momentB === null || momentB === undefined) {
		momentB = -Infinity;
	}

	// force any string values to lowercase
	if (momentA === 'string') {
		momentA.toLowerCase();
	}

	if (momentB === 'string') {
		momentB.toLowerCase();
	}

	// Return either 1 or -1 to indicate a sort priority
	if (momentA > momentB) {
		return 1;
	}
	if (momentA < momentB) {
		return -1;
	}
	// returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
	return 0;
};

export default sortDates;
