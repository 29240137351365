import $ from 'jquery';

/*
Transposes table (in place)
*/
export default table => {
	const jqTable = $(table);

	jqTable
		.find('thead tr')
		.detach()
		.prependTo(jqTable.find('tbody'));
	const t = jqTable.find('tbody').eq(0);
	const r = t.find('tr');
	const cols = r.length;
	const rows = r.eq(0).find('td,th').length;
	let cell;
	let next;
	let tem;
	let i = 0;
	const tb = $('<tbody> </tbody>');

	while (i < rows) {
		cell = 0;
		tem = $('<tr> </tr>');
		while (cell < cols) {
			next = r
				.eq(cell++)
				.find('td,th')
				.eq(0);
			tem.append(next);
		}
		tb.append(tem);
		++i;
	}
	jqTable.find('tbody').remove();
	$(tb).appendTo(jqTable);
	jqTable
		.find('tbody tr:eq(0)')
		.detach()
		.appendTo(jqTable.find('thead'))
		.children()
		.each(() => {
			$(this).replaceWith(`<th scope="col">${$(this).html()}</th>`);
		});
	jqTable.find('tbody tr th:first-child').each(() => {
		$(this).replaceWith(`<td scope="row">${$(this).html()}</td>`);
	});
	jqTable.show();
};
